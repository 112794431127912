import { useQuery, useMutation, useQueryCache } from 'react-query';
import { request } from 'api/api';
import { openNotificationWithIcon } from "../../common/Notification/NotificationToster";
import get from "lodash/get";
import { appServices } from 'core/services';
import { useTranslation } from "react-i18next";
import { commonServices } from './commonServices';

var ContractsService = function () {

  let currentPage = 1;
  const useAllContracts = (searchQuery: any, pagination: any, setLoadingData?: any) => {
    const queryCache = useQueryCache();
    currentPage = pagination.page;
    const params = commonServices.getListQueryParams([`useAllContracts/${searchQuery.parentID}`, currentPage], searchQuery, pagination, queryCache);

    const response = useQuery(
      [`useAllContracts/${searchQuery.parentID}`, currentPage],
      () => request.get(`/v2/licensing/accounts/${searchQuery.parentID}/contracts?include_waiting_for_payment=true`, { params: params }).then((res) => {
        if (setLoadingData) {
          setLoadingData(false);
        }
        return res.data;
      }),
      {
        enabled: searchQuery.parentID,
        keepPreviousData: true,
        retry: false
      }
    );

    const { status, data } = response;
    if (status === 'error' || status === 'loading' || status === 'idle') {
      for (let i = 0; i < pagination.size; i++) {
        // eslint-disable-next-line
        const { data: app } = appServices.useGetAppInfoById('');
      }
    } else if (status === 'success' && data) {
      for (let i = 0; i < pagination.size; i++) {
        const appId = (data.content[i]) ? data.content[i].app_id : '';
        const { status: appStatus, data: app } = appServices.useGetAppInfoById(appId);
        if (appStatus === 'success' && app) {
          data.content[i].appName = app.name;
        } else if (data.content[i]) {
          data.content[i].appName = '-'
        }
      }
    }
    return response;
  }

  const useGetMetaTitle = (searchQuery: any) => {
    const { data: app } = appServices.useGetAppById(searchQuery.parentID[1]);
    return useQuery(
      [`useAllContracts/metaInfo`, searchQuery.parentID[0]],
      () => request.get(`/v2/plans/${searchQuery.parentID[0]}`).then(res => {
        res.data.name = `${app.name} - ${res.data.name}`;
        return res.data && res.data.content;
      }),
      {
        enabled: app,
        retry: false
      }
    );
  }

  const useCreateEditContract = () => {
    const { t } = useTranslation();
    return useMutation(
      (params) => {
        if (params.id) {
          const updateParams = Object.assign({}, params);
          delete updateParams.id;
          return request.put(`/v2/licensing/accounts/${params.accountId}/contracts/${params.id}`, updateParams);
        } else {
          return request.post(`/v2/licensing/accounts/${params.accountId}/contracts`, params);
        }
      },
      {
        onMutate: (params: any) => {
        },
        onSuccess: (response, params) => {
          openNotificationWithIcon(
            'success',
            params.id ? t('Contract updated successfully') : t('Contract created successfully'),
            'bottomRight'
          );
        },
        onError: (error: any, _params: any, rollback: any) => {
          openNotificationWithIcon(
            "error",
            `${t('sorry, something wrong happened. Response from server is')} : 
            ${get(error, "response.status")}, ${get(error, "response.data.error")}`,
            "bottomLeft"
          );
          if (rollback) rollback()
        },
        onSettled: () => {

        }
      }
    );
  }

  const useGetContractById = (contractId: string, accountId: string) => {
    return useQuery(
      ['useGetContractById', contractId],
      () => request.get(`/v2/licensing/accounts/${accountId}/contracts/${contractId}`).then(res => res.data),
      {
        enabled: contractId && contractId !== '' && accountId,
        retry: false
      }
    );
  }

  const useUpdateQuotaUsage = () => {
    const { t } = useTranslation();
    return useMutation(
      (params) => {
        const updateParams = Object.assign({}, params);
        delete updateParams.id;
        delete updateParams.original_id;
        return request.put(`/v2/licensing/accounts/${params.account_id}/contracts/${params.id}`, updateParams);
      },
      {
        onMutate: (params: any) => {
        },
        onSuccess: (response, params) => {
          openNotificationWithIcon(
            'success',
            t('Quota usage updated successfully'),
            'bottomRight'
          );
        },
        onError: (error: any, _params: any, rollback: any) => {
          openNotificationWithIcon(
            "error",
            `${t('sorry, something wrong happened. Response from server is')} : 
            ${get(error, "response.status")}, ${get(error, "response.data.error")}`,
            "bottomLeft"
          );
          if (rollback) rollback()
        },
        onSettled: () => {
        }
      }
    );
  }

  const useDeleteContractById = () => {
    const { t } = useTranslation();
    const queryCache = useQueryCache();
    return useMutation(({ accountId, contractId }) => request.delete(`/v2/licensing/accounts/${accountId}/contracts/${contractId}`),
      {
        onMutate: (id) => {
          const oldContracts = queryCache.getQueryData([`useAllContracts`, currentPage]);
          return () => queryCache.setQueryData([`useAllContracts`, currentPage], oldContracts);
        },
        onSuccess: () => {
          openNotificationWithIcon('success', t('Contract deleted successfully'), 'bottomRight');
        },
        onError: (error: any, _params: any, rollback: any) => {
          openNotificationWithIcon(
            'error',
            `${t('Sorry, something wrong happened. Response from server is')} : 
            ${get(error, 'response.status')}, ${get(error, 'response.data.error')}`,
            'bottomLeft'
          );
          if (rollback) rollback()
        },
        onSettled: () => {
          queryCache.invalidateQueries([`useAllContracts`, currentPage], { exact: true });
        }
      }
    )
  }

  return {
    useAllContracts: useAllContracts,
    useGetMetaTitle: useGetMetaTitle,
    useCreateEditContract: useCreateEditContract,
    useGetContractById: useGetContractById,
    useUpdateQuotaUsage: useUpdateQuotaUsage,
    useDeleteContractById: useDeleteContractById,
  };
}();

export default ContractsService;
