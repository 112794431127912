import { store } from 'app-redux/store';
import React from 'react';
import ReactDOM from 'react-dom';
import { ReactQueryDevtools } from 'react-query-devtools';
import { Provider } from 'react-redux';
import App from './App';
import AppProviders from './context';
import './core/i18n';
import { CWElementsClient } from 'cw-elements';
import { getAccessToken, isUK } from 'core/common-methods';
import { getCurrentEnv } from 'core/utils/commonMethods';
import { request } from 'api/api';

const initClients = () => {
  const cwElementsClient = CWElementsClient.getInstance();

  let tokens;
  try {
    tokens = JSON.parse(getAccessToken());
  } catch (ignored) {
    tokens = null;
  }

  cwElementsClient.setCurrentEnv(getCurrentEnv() as 'qa' | 'prod' | 'beta' | 'staging');
  // TODO: Export interface on cw-elements
  cwElementsClient.setupAxiosInterceptor(request as import("cw-elements/node_modules/axios/index").AxiosInstance)

  if (isUK()) {
    cwElementsClient.setCustomHeaders({
      'accept-language': 'en-GB',
    });
  }

  if (tokens && tokens.access_token) {
    cwElementsClient.setToken(tokens.access_token);
  }
};

initClients();

ReactDOM.render((
  <Provider store={store}>
      <AppProviders>
        <App />
        {process.env.REACT_APP_ENV === 'local' && (
          <ReactQueryDevtools initialIsOpen={false} position="bottom-right"/>
        )}
    </AppProviders>
  </Provider>
),
document.getElementById('root'),
);
