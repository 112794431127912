import { useQuery, useQueryCache, useMutation } from 'react-query';
import { request } from 'api/api';
import { openNotificationWithIcon } from "../../common/Notification/NotificationToster";
import get from "lodash/get";
import { useTranslation } from "react-i18next";
import { commonServices } from './commonServices';
import { plansService } from './index';
import moment from 'moment';

var campaignService = function () {

  let currentPage = 1;
  let currentVouchersPage = 1;
  const useAllCampaigns = (searchQuery: any, pagination: any, setLoadingData?: any, userHasRBACPermission: boolean = false, filterValue?: any) => {
    const queryCache = useQueryCache();
    currentPage = pagination.page - 1;
    const params = commonServices.getListQueryParams([`useAllCampaigns`, currentPage], searchQuery, pagination, queryCache);
    params.page = currentPage;
    const appId = filterValue;

    return useQuery(
      [`useAllCampaigns`, currentPage, appId],
      () => {
        if (appId) {
          setLoadingData(true);
          return (
            request.get(`/v2/apps/${appId}/campaigns`, { params: params }).then((res) => {
              if (setLoadingData) {
                setLoadingData(false);
              }
              return res.data;
            })
          );
        }
        return undefined;
      },
      {
        keepPreviousData: true,
        retry: false
      }
    );
  }

  const useGetCampaignById = (id: string) => {
    return useQuery(
      ['useCampaignsDetails', id],
      () => request.get(`/v2/campaigns/${id}`).then(res => {
        res.data.startDate = moment(res.data.start_date)
        res.data.endDate = moment(res.data.end_date)
        return res.data
      }
      ),
      {
        enabled: id,
        retry: false
      }
    );
  }

  const useAllCampaignvouchers = (searchQuery: any, pagination: any, setLoadingData?: any) => {
    const queryCache = useQueryCache();
    currentVouchersPage = pagination.page;
    const params = commonServices.getListQueryParams([`useAllCampaignvouchers/${searchQuery.parentID}`, currentVouchersPage], searchQuery, pagination, queryCache);
    return useQuery(
      [`useAllCampaignvouchers/${searchQuery.parentID}`, currentVouchersPage],
      () => request.get(`/v2/campaigns/${searchQuery.parentID}/vouchers`, { params: params }).then((res) => {
        if (setLoadingData) {
          setLoadingData(false);
        }
        return res.data;
      }),
      {
        enabled: searchQuery.parentID,
        keepPreviousData: true,
        retry: false
      }
    );
  }

  const useCreateEditCampaign = () => {
    const { t } = useTranslation();
    const queryCache = useQueryCache();
    const cleanParams = (params: any) => {
      const res = Object.assign({}, params);
      delete res.id;
      return res;
    };
    return useMutation(
      (params) => params.id ? request.put(`/v2/campaigns/${params.id}`, cleanParams(params)) : request.post('/v2/campaigns/', params),
      {
        onSuccess: (response, params) => {
          openNotificationWithIcon(
            'success',
            params.id ? t('Campaign updated successfully') : t('Campaign created successfully'),
            'bottomRight'
          );
          queryCache.invalidateQueries([`useCampaignsDetails`, params.id]);
        },
        onError: (error: any, _params: any, rollback: any) => {
          openNotificationWithIcon(
            "error",
            `${t('sorry, something wrong happened. Response from server is')} : 
            ${get(error, "response.status")}, ${get(error, "response.data.error")}`,
            "bottomLeft"
          );
          if (rollback) rollback()
        },
        onSettled: () => {
          queryCache.invalidateQueries([`useAllCampaigns`, currentPage]);
        }
      }
    );
  }

  const useGenerateVouchers = () => {
    const { t } = useTranslation();
    const queryCache = useQueryCache();
    return useMutation((params) => request.post(`/v2/campaigns/${params.campaignId}/vouchers`, params),
      {
        onMutate: (params: any) => {
          const oldAccounts = queryCache.getQueryData(['useCampaignsDetails', params.campaignId]);
          return () => queryCache.setQueryData(['useCampaignsDetails', params.campaignId], oldAccounts);
        },
        onSuccess: (response, params) => {
          openNotificationWithIcon('success', t('Voucher created successfully'), 'bottomRight');
          queryCache.invalidateQueries([`useAllCampaignvouchers/${params.campaignId}`, 1], { exact: true });
        },
        onError: (error: any, _params: any, rollback: any) => {
          openNotificationWithIcon(
            "error",
            `${t('sorry, something wrong happened. Response from server is')} : 
            ${get(error, "response.status")}, ${get(error, "response.data.error")}`,
            "bottomLeft"
          );
          if (rollback) rollback()
        },
        onSettled: () => {
          // queryCache.invalidateQueries(['useAllCampaigns', (params as any).id], { exact: true}); 
        }
      }
    )
  }

  const useShareCampaign = () => {
    const { t } = useTranslation();
    return useMutation(({ id, ...params }) => request.post(`/v2/campaigns/${id}/shareable-link`, params),
      {
        onSuccess: () => {
          openNotificationWithIcon('success', t('Share link created successfully'), 'bottomRight');
        },
        onError: (error: any, _params: any, rollback: any) => {
          openNotificationWithIcon(
            "error",
            `${t('sorry, something wrong happened. Response from server is')} : 
            ${get(error, "response.status")}, ${get(error, "response.data.error")}`,
            "bottomLeft"
          );
          if (rollback) rollback()
        }
      }
    )
  }

  const useUpdateShareCampaign = () => {
    const { t } = useTranslation();
    const queryCache = useQueryCache();
    return useMutation(({ id, link_id, ...params }) => request.put(`/v2/campaigns/${id}/shareable-link/${link_id}`, params),
      {
        onSuccess: (response, params) => {
          openNotificationWithIcon('success', t('Share link created successfully'), 'bottomRight');
          queryCache.invalidateQueries(['useCampaignsDetails', params.id], { exact: true });
          queryCache.invalidateQueries([`useAllCampaignvouchers/${params.id}`, 1], { exact: true });
        },
        onError: (error: any, _params: any, rollback: any) => {
          openNotificationWithIcon(
            "error",
            `${t('sorry, something wrong happened. Response from server is')} : 
            ${get(error, "response.status")}, ${get(error, "response.data.error")}`,
            "bottomLeft"
          );
          if (rollback) rollback()
        }
      }
    )
  }

  const useDeleteCampaignById = () => {
    const { t } = useTranslation();
    const queryCache = useQueryCache();
    return useMutation((id) => request.delete(`/v2/campaigns/${id}`),
      {
        onMutate: (id) => {
          const oldAccounts = queryCache.getQueryData([`useAllCampaigns`, currentPage]);
          return () => queryCache.setQueryData([`useAllCampaigns`, currentPage], oldAccounts);
        },
        onSuccess: () => {
          openNotificationWithIcon('success', t('Campaign deleted successfully'), 'bottomRight');
        },
        onError: (error: any, _params: any, rollback: any) => {
          openNotificationWithIcon(
            "error",
            `${t('sorry, something wrong happened. Response from server is')} : 
            ${get(error, "response.status")}, ${get(error, "response.data.error")}`,
            "bottomLeft"
          );
          if (rollback) rollback()
        },
        onSettled: () => {
          queryCache.invalidateQueries([`useAllCampaigns`, currentPage], { exact: true });
        }
      }
    )
  }

  const useGetAppPlans = (apps: any = []) => {
    return apps?.map((app: any) => {
      const { data: appPlans } = plansService.useAllPlans({ parentID: app.id }, { page: 1, last_key: '', size: 9999 });
      return appPlans ? appPlans?.content : [];
    });
  }

  const useDesactiveCampaign = () => {
    return useMutation((campaignId: any) => request.put(`/v2/campaigns/${campaignId}/activation-toggle`),
      {
        onError: (error: any) => {
          console.log(error)
        },
        onSuccess: () => {
          openNotificationWithIcon('success', 'Campaign Deactivated successfully', 'bottomRight');
        },
      }
    );
  };
  return {
    useAllCampaigns: useAllCampaigns,
    useGetCampaignById: useGetCampaignById,
    useCreateEditCampaign: useCreateEditCampaign,
    useGenerateVouchers: useGenerateVouchers,
    useUpdateShareCampaign: useUpdateShareCampaign,
    useAllCampaignvouchers: useAllCampaignvouchers,
    useShareCampaign: useShareCampaign,
    useDeleteCampaignById: useDeleteCampaignById,
    useGetAppPlans: useGetAppPlans,
    useDesactiveCampaign: useDesactiveCampaign,
  };
}();

export default campaignService;
