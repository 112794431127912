import React, { Suspense, useEffect } from 'react';
import { Route, Router, Switch, Redirect } from 'react-router';
import styled from 'styled-components';
import get from 'lodash/get';
import { useTranslation } from "react-i18next";
import { useAuth } from './context/auth';
import { history } from 'core/history';
import { Loader } from './components/loader';

import './main.less';
import './api/api';
import { isAccessTokenAvailable } from 'core/common-methods';
import { initRollout } from 'app-redux/actions/rollout.actions';
import { connect } from 'react-redux';

import 'cw-elements/lib/styles/index.less'
import 'syngenta-digital-cropwise-react-ui-kit/dist/styles/cw-ui-kit.less';

export const StyledRoot = styled.div`
  font-family: ${(props) => get(props, 'theme.font.family')};
  height: 100%;
  min-height: 100%;
  * {
    box-sizing: border-box;
  }
`;

interface IAppComponentPros {
  initRollout?: (configs: any) => Promise<any>;
}

const AsyncMainContainer = React.lazy(() => import('pages/main-container'));

export const App: React.FC<IAppComponentPros> =  (props) => {
  const { user } = useAuth();
  const { t } = useTranslation();

  useEffect(() => {
    if (isAccessTokenAvailable() && user && props.initRollout) {
      props.initRollout({ userContext: { id: user.id } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);
  
  if (!user) {
    return null;
  }

  return (
    <StyledRoot>
      <Router history={history}>
        <Suspense fallback={<Loader tip={t('Loading Application...')} />}>
          <Switch>
            <Route exact path="/app/*" component={AsyncMainContainer} />
            <Redirect from="*" to="/app/home" />
          </Switch>
        </Suspense>
      </Router>
    </StyledRoot>
  );
};

const mapStateToProps = () => {};

const mapDispatchToProps = (dispatch: any) => ({
  initRollout: (configs: any) => dispatch(initRollout(configs)),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
