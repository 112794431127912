import { first, last } from 'lodash';
import isEmpty from 'lodash/isEmpty';
import { O_AUTH, ENC_KEY } from '../constants';

export const isAccessTokenAvailable = () => !!localStorage.getItem('tokens');
export const setAccessToken = (token) => localStorage.setItem('tokens', token);
export const getAccessToken = () => {
  try {
    return JSON.parse(localStorage.getItem('tokens')).access_token
  } catch (e) {
    return null;
  }
}
export const clearAccessToken = () => localStorage.removeItem('tokens');

export const getAppHostsInfo = () => {
  const domainMapping = {
    'localhost:3000': { host: 'GLOBAL', account: 'qa.accounts.cropwise.com' },
    'staff.qa.cropwise.com': { host: 'GLOBAL', account: 'qa.accounts.cropwise.com' },
    'beta.staff.cropwise.com': { host: 'GLOBAL', account: 'beta.accounts.cropwise.com' },
    'staff.staging.cropwise.com': { host: 'GLOBAL', account: 'staging.accounts.cropwise.com' },
    'staff.cropwise.com': { host: 'GLOBAL', account: 'accounts.cropwise.com' },
  };
  const { host } = window.location;
  return {
    host: (!isEmpty(domainMapping[host]) && domainMapping[host].host) || 'GLOBAL',
    account: !isEmpty(domainMapping[host]) && domainMapping[host].account,
    hostsList: [...new Set(Object.values(domainMapping).map((d) => d.host))]
  };
};

export const generateCampaignLink = (cId, linkId, type, redirect_uri = '') => {
  const { account } = getAppHostsInfo();
  const refId = btoa(`${cId}::${linkId}::${ENC_KEY}`);
  const redirect = (redirect_uri === '') ? '' : `&redirect_uri=${redirect_uri}`
  if (type === 1) {
    return `https://${account}/signup?source_campaign=${refId}${redirect}`;
  }
  if (type === 2) {
    return `https://${account}/signin?source_campaign_signin=${refId}${redirect}`;
  }
  else {
    console.error('Invalid shareable link type.')
    return 'invalid_type_campaign_link';
  }
}

export const getOAuthUrl = () => {
  const { account } = getAppHostsInfo();
  return `https://${account}/oauth/authorize?response_type=${O_AUTH.responseCode}&client_id=${O_AUTH.clientId}&redirect_uri=${window.location.origin}/authenticate`;
};

export const getPaginationKeyArr = (paginationKeyArray, currentPage, data) => {
  const temp = paginationKeyArray;
  temp[currentPage] = {
    current_key: data.pageable.current_key,
    next_key: data.pageable.next_key
  }
  return temp;
}

export const setPaginationLastKey = (paginationSizeChanged, pagination, currentPage, paginationKeyArray, setLastKey, data) => {
  if (paginationSizeChanged === true) {
    setLastKey('');
    paginationSizeChanged = false;
  } else {
    if (pagination.current < currentPage && (pagination.current in paginationKeyArray)) {
      setLastKey(paginationKeyArray[pagination.current].current_key);
    } else {
      setLastKey(data.pageable.next_key);
    }
  }
}

export const getShortName = (name) => {
  if (isEmpty(name)) {
    return '';
  }
  const splitedNames = name.split(' ');
  const firstName = first(splitedNames) || '';
  const lastName = last(splitedNames) || '';
  if (splitedNames.length >= 2) {
    return `${firstName.charAt(0).toUpperCase()}${lastName.charAt(0).toUpperCase()}`;
  }
  return firstName.charAt(0).toUpperCase();
};

export const getCurrentEnv = () => {
  const currentEnv = process.env.REACT_APP_ENV;
  switch (currentEnv) {
    case 'local':
      return 'qa';
    case 'master':
      return 'prod';
    default:
      return currentEnv;
  }
};