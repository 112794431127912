import { useQuery,useQueryCache, useMutation } from 'react-query';
import { useLocation } from 'react-router-dom';
import { request } from 'api/api';
import { openNotificationWithIcon } from "../../common/Notification/NotificationToster";
import get from "lodash/get";
import { useTranslation } from "react-i18next";
import { commonServices } from './commonServices';
import { isUserPrivileged } from 'core/common-methods';

var organizationService = function () {

  let currentPage = 1;
  let currentPropertyPage = 1;
  let currentSeasonsPage = 1;
  let currentContractsPage = 1;
  let currentUsersPage = 1;

  const useAllOrganizations = (searchQuery: any, pagination: any, setLoadingData?: any, userHasRBACPermission: boolean = false) => {
    const queryCache = useQueryCache();
    currentPage = pagination.page - 1;
    const params = {
      ...commonServices.getListQueryParams([`useAllOrganizations`, currentPage], searchQuery, pagination, queryCache),
      attributes: 'licensing_accounts',
      licensing_accounts: typeof(searchQuery) === 'string' ? searchQuery : undefined,
      force_list_unexplicit_orgs: (isUserPrivileged() || userHasRBACPermission),
      page: currentPage,
    };

    return useQuery(
      `useAllOrganizations/${currentPage}`,
      () => request.get('/v2/orgs', { params: params }).then((res) => {
        if (setLoadingData) {
          setLoadingData(false);
        }
        return res.data;
      }),
      {
        keepPreviousData: true,
        retry: false
      }
    );
  }

  const useGetAllAccountsForOrg = (vs: boolean, refId: string) => {
    const params = {
      size: 9999999,
      page: 1,
      last_key: ''
    }
    return useQuery(
      [`useAllOrganizations/useAllAccounts`, 1],
      () => request.get(`/v2/licensing/accounts`, { params: params }).then(res => {
        return res.data && res.data
      }),
      {
        enabled: vs === true || refId === null,
        retry: false
      }
    );
  }

  const useGetOrgById = (orgID: string) => {
    return useQuery(
      ['useAllOrganizations', orgID],
      () => request.get(`/v2/orgs/${orgID}`).then(res => res.data),
      {
        enabled: orgID,
        retry: false
      }
    );
  }

  const useAllOrgusers = (searchQuery: any, pagination: any) => {
    const queryCache = useQueryCache();
    currentUsersPage = pagination.page;
    const params = commonServices.getListQueryParams([`orgs/users/${searchQuery.parentID}`, currentUsersPage], searchQuery, pagination, queryCache);
    return useQuery(
      [`orgs/users/${searchQuery.parentID}`, currentUsersPage],
      () => request.get(`/v2/orgs/${searchQuery.parentID}/accounts/`, { params: params }).then(res => res.data),
      {
        enabled: searchQuery.parentID,
        keepPreviousData: true,
        retry: false
      }
    );
  }

  const useAllAllowedOrgs = (licensing_accounts: any, page: number = 0, size: number = 10) => {
    return useQuery(
      ['useAllAllowedOrgs', 1],
      () => request.get(`/v2/orgs?size=${size}&page=${page}&last_key=&attributes=licensing_accounts&force_list_unexplicit_orgs=true&licensing_accounts=${licensing_accounts}`).then(res => res.data),
      {
        enabled: licensing_accounts,
        retry: false
      }
    );
  }

  const useAllOrgproperties = (searchQuery: any, pagination: any) => {
    const queryCache = useQueryCache();
    currentPropertyPage = pagination.page - 1;
    const params = commonServices.getListQueryParams([`orgs/properties/${searchQuery.parentID}`, currentPropertyPage], searchQuery, pagination, queryCache);
    params.page = currentPropertyPage;
    return useQuery(
      [`orgs/properties/${searchQuery.parentID}`, currentPropertyPage],
      () => request.get(`/v2/orgs/${searchQuery.parentID}/properties`, { params: params }).then(res => res.data),
      {
        enabled: searchQuery.parentID,
        keepPreviousData: true,
        retry: false
      }
    );
  }

  const useAllOrgseasons = (searchQuery: any, pagination: any) => {
    const queryCache = useQueryCache();
    currentSeasonsPage = pagination.page - 1;
    const params = commonServices.getListQueryParams([`orgs/seasons/${searchQuery.parentID}`, currentSeasonsPage], searchQuery, pagination, queryCache);
    params.page = currentSeasonsPage;

    const { data: crops } = useQuery(
      ['orgs/seasons/crops'],
      () => request.get(`/v2/crops?showDeleted=false&location=@BR`).then(res => res.data.content),
      {
        enabled: searchQuery.parentID,
        retry: false
      }
    );

    return useQuery(
      [`orgs/seasons/${searchQuery.parentID}`, currentSeasonsPage],
      () => request.get(`/v2/orgs/${searchQuery.parentID}/seasons`, { params: params }).then(res => {
        const updatedContent = res.data.content.map((season: any) => {
          const filterCrops = crops.filter((crop: any) => {
            return crop.id === season.crop_id
          })
          if (filterCrops.length > 0) {
            season.wk_slug = filterCrops[0].wk_slug;
          }
          return season;
        });
        res.data.content = updatedContent;
        return res.data;
      }),
      {
        enabled: crops,
        keepPreviousData: true,
        retry: false
      }
    );
  }

  function handleUpdate(params: any) {
    const data = new FormData();
    data.append('file', params.orgImage);
    request.post(`/v2/orgs/${params.id}/picture`, data)
  }

  const useCreateEditOrg = () => {
    const { t } = useTranslation();
    let location = useLocation() || '';
    const queryCache = useQueryCache();

    return useMutation(
      (params) => {
        if ((params.id)) {
          handleUpdate(params)
        }
        return (params.id) ? request.put(`/v2/orgs/${params.id}`, params) : request.post('/v2/orgs', params)
      },
      {
        onMutate: (params: any) => {
          /* if(params.id) {
              queryCache.cancelQueries(['useAllOrganizations', params.id]);
              const oldOrgs = queryCache.getQueryData(['useAllOrganizations', params.id]); 
              queryCache.setQueryData(['useAllOrganizations', params.id], params);
              return () => queryCache.setQueryData(['useAllOrganizations', params.id], oldOrgs);
          } else {
              queryCache.cancelQueries('useAllOrganizations'); 
              const oldOrgs = queryCache.getQueryData('useAllOrganizations');
              queryCache.setQueryData('useAllOrganizations', (old:any) => [...old, params])
              return () => queryCache.setQueryData('useAllOrganizations', oldOrgs)
          } */
        },
        onSuccess: (response, params) => {
          openNotificationWithIcon(
            "success",
            params.id ? t("Organization updated successfully") : t("Organization created successfully"),
            "bottomRight"
          );
        },
        onError: (error: any, _params: any, rollback: any) => {
          openNotificationWithIcon(
            "error",
            `${t('sorry, something wrong happened. Response from server is')} : 
            ${get(error, "response.status")}, ${get(error, "response.data.error")}`,
            "bottomLeft"
          );
          if (rollback) rollback();
        },
        onSettled: () => {
          if ((location as any).pathname.indexOf('app/account/') !== -1) {
            const locationArr = (location as any).pathname.split('/');
            queryCache.invalidateQueries([`account/orgs/${locationArr[locationArr.length - 1]}`, 0]);
          } else {
            queryCache.invalidateQueries([`useAllOrganizations`, currentPage]);
          }
        },
      }
    )
  }

  const useAllOrgcontracts = (searchQuery: any, pagination: any) => {
    const queryCache = useQueryCache();
    currentContractsPage = pagination.page;
    const params = commonServices.getListQueryParams([`orgs/contracts/${searchQuery.parentID}`, currentContractsPage], searchQuery, pagination, queryCache);
    return useQuery(
      [`orgs/contracts/${searchQuery.parentID}`, currentContractsPage],
      () => request.get(`/v2/licensing/accounts/d1a2265e-cc72-4e93-8267-8e2f6d3ed796/contracts/`, { params: params }).then(res => res.data),
      {
        enabled: searchQuery.parentID,
        keepPreviousData: true,
        retry: false
      }
    );
  }

  const useDeleteOrgById = () => {
    const { t } = useTranslation();
    const queryCache = useQueryCache();
    let location = useLocation() || '';
    return useMutation((id) => request.delete(`/v2/orgs/${id}`), {
      onMutate: (id) => {
        const oldOrgs = queryCache.getQueryData([`useAllOrganizations`, currentPage]);
        return () => queryCache.setQueryData([`useAllOrganizations`, currentPage], oldOrgs);
      },
      onSuccess: () => {
        openNotificationWithIcon('success', t('Organization deleted successfully'), 'bottomRight');
      },
      onError: (error: any, _params: any, rollback: any) => {
        openNotificationWithIcon(
          "error",
          `${t('sorry, something wrong happened. Response from server is')} : 
          ${get(error, "response.status")}, ${get(error, "response.data.error")}`,
          "bottomLeft"
        );
        if (rollback) rollback()
      },
      onSettled: () => {
        queryCache.invalidateQueries([`useAllOrganizations`, currentPage], { exact: true });
        if ((location as any).pathname.indexOf('app/account/') !== -1) {
          const locationArr = (location as any).pathname.split('/');
          queryCache.invalidateQueries([`account/orgs/${locationArr[locationArr.length - 1]}`, 0]);
        } else {
          queryCache.invalidateQueries([`useAllOrganizations`, currentPage]);
        }
      }
    })
  }

  return {
    useAllOrganizations: useAllOrganizations,
    useAllOrgusers: useAllOrgusers,
    useAllOrgproperties: useAllOrgproperties,
    useAllOrgseasons: useAllOrgseasons,
    useCreateEditOrg: useCreateEditOrg,
    useGetOrgById: useGetOrgById,
    useAllOrgcontracts: useAllOrgcontracts,
    useDeleteOrgById: useDeleteOrgById,
    useGetAllAccountsForOrg: useGetAllAccountsForOrg,
    useAllAllowedOrgs: useAllAllowedOrgs
    
  };
}();

export default organizationService;
